/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    div: "div",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Últimamente ando trastenado con Sway, un gestor de ventanas de tipo mosaico que utiliza el protocolo Wayland en lugar de X11. Sway es en sí un compositor Wayland que acomoda las ventanas según una lógica similar a i3, se puede considerar el sustituto del mítico i3 para Wayland."), "\n", React.createElement(_components.p, null, "Como pantalla de login sigo utilizando SDDM, el gestor por defecto de plasma que ya usaba, pero me he encontrado con un problema al utilizar Wayland, también me pasa con la versión Wayland de Plasma. Los iconos de las aplicaciones instaladas mediante Flatpak no aparecen en los lanzadores. En este caso estoy usando ", React.createElement(_components.em, null, "Krunner"), "."), "\n", React.createElement(_components.p, null, "Por alguna razón las variables de entorno de systemd que se definen mediante el módulo PAM. Para poder pasar estas variables a mi sesión y definir las que yo quiera he personalizado mi lanzador de Sway."), "\n", React.createElement(_components.p, null, "Para esto creo el archivo ", React.createElement(_components.em, null, "/usr/share/wayland-sessions/sway-native.desktop"), " con el siguiente contenido:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">[Desktop Entry]   Type=Application   Name=Sway-native   Comment=An i3-compatible Wayland compositor (with environment variables)   Exec=/usr/local/bin/startsway.sh</code>"
    }
  })), "\n", React.createElement(_components.p, null, "El anterior archivo define que se ejecutará el archivo ", React.createElement(_components.em, null, "/usr/local/bin/startsway.sh"), " para iniciar la sesión, así que vamos a crear este script."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">#! /bin/sh      # systemd PAM environment   export $(systemctl --user show-environment)      # force apps to use Wayland backend by default   export MOZ_ENABLE_WAYLAND=1   export QT_QPA_PLATFORM=wayland-egl   export QT_WAYLAND_DISABLE_WINDOWDECORATION=1   export CLUTTER_BACKEND=wayland   export ECORE_EVAS_ENGINE=wayland_egl   export ELM_ENGINE=wayland_egl   export SDL_VIDEODRIVER=wayland   export _JAVA_AWT_WM_NONREPARENTING=1   #export XDG_CURRENT_DESKTOP=sway      # no redirecting stdout/stderr; handled by SDDM   exec sway</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Este script en primer lugar exportará las variables de entorno de systemd. A continuación defino algunas variables recomendables para ejecutar wayland siempre que sea posible. Por ultimo lanzará sway."), "\n", React.createElement(_components.p, null, "Después tenemos que hacer ejecutable este archivo:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">chmod +x /usr/local/bin/startsway.sh</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Reinicio y compruebo que puedo ejecutar mis ", React.createElement(_components.em, null, "flatfaks"), " para tener las aplicaciones siempre actualizadas a la versión del desarrollador."), "\n", React.createElement(_components.p, null, "Basado en: ", React.createElement(_components.a, {
    href: "https://www.reddit.com/r/swaywm/comments/g20cbw/keepassxc_behaves_bizarrely_if_sway_is_started/"
  }, "https://www.reddit.com/r/swaywm/comments/g20cbw/keepassxc_behaves_bizarrely_if_sway_is_started/")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
